import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from 'src/environments/environment';
import { LoginRequest, LoginResponse } from '../models/authentication/auth.model';
import { SessionService } from './session.service';

export const InterceptorSkip = 'X-Skip-Interceptor';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {

  fetchUsersAction = new BehaviorSubject(false);
  onFormReset = new Subject<void>();

  InterceptorSkipHeader = new HttpHeaders({
    'X-Skip-Interceptor': ''
  });

  constructor(private http: HttpClient, private router: Router, private sessionService: SessionService) { }

  resetForm(): void {
    this.onFormReset.next();
  }

  login(loginData: LoginRequest): Observable<LoginResponse>{
    return this.http.post<LoginResponse>(environment.AUTHENTICATION_API_URL + '/login',
      loginData,
      { headers: this.InterceptorSkipHeader })
      .pipe(
        map((response: LoginResponse) => {
          this.sessionService.setSessionObject(response);
          return response;
        })
      );
  }

  logout(): void {
    this.sessionService.clearSession();
    this.router.navigate(['/auth/login']);
  }

}
